




















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import VenueEditSubSteps from '@/components/venues/venue-steps/venue-edit-sub-steps.vue';
import VenueWebFormStep from '@/components/venues/venue-steps/venue-web-form-step.vue';
import VenueQrCodeStep from '@/components/venues/venue-steps/venue-qr-code-step.vue';
import { IVenue } from '@einfachgast/shared';
import { ValidationError } from 'class-validator';

@Component({
  name: 'VenueDesignStep',
  components: {
    VenueEditSubSteps,
    VenueQrCodeStep,
    VenueWebFormStep,
  },
})
export default class VenueDesignStep extends Vue {
  @Prop()
  venue: IVenue;

  @Prop()
  activeTab: string;

  @Prop({ default: (): ValidationError[] => [] })
  errors: ValidationError[];

}
